<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="Order_Details_content wrap bgw">
					<div v-if="state==0" class="state">
						<el-steps :active="state1" align-center>
							<el-step title="拍下商品" description></el-step>
							<el-step title="付款" description></el-step>
							<el-step title="卖家发货" description></el-step>
							<el-step title="确认收货" description></el-step>
							<el-step title="评价" description></el-step>
						</el-steps>
					</div>
					<div v-if="state==1" class="state">
						<el-steps :active="state2" align-center>
							<el-step title="卖家申请退款" description></el-step>
							<el-step title="卖家处理退款申请" description></el-step>
							<el-step title="商家确认退款" description></el-step>
						</el-steps>
					</div>
					<div class="txt">
						<div class="fl order_txt">
							<div>订单信息</div>
							<p class="one">下单时间：{{list.ordertime}}</p>
							<p class="one">订单编号：{{list.out_trade_no}}</p>
							<p v-if="states>0 &&  states!=5 &&list.paytime" class="one">付款时间：{{list.paytime}}</p>
							<p v-if="states>1 &&  states!=5 &&!list.postno && states != 6" class="one">发货时间：{{list.sendtime}}</p>
							<p v-if="states>0 && states!=5 && list.username" class="one">付款方式：{{list.pay_type_txt}}</p>
							<p v-if="states>0 && states!=5 && list.address" class="one">配送方式： {{list.post}}</p>
							<p v-if="states>1 && states!=5 && list.address" class="one">物流公司： {{list.postname}}</p>
							<p v-if="states>1 && states!=5 && list.address" class="one">发货时间： {{list.sendtime}}</p>
							<p v-if="states>1 && states!=5 && list.address" class="one">物流编号： {{list.postno}}</p>
							<p v-if="states>0 && states!=5 && list.username" class="one">收货人：{{list.username}}</p>
							<p v-if="states>0 && states!=5 && list.address" class="one">地址： {{list.address}}</p>
							<p v-if="states>0 && states!=5 && list.mobile" class="one">电话：{{list.mobile}}</p>
						</div>
						<!-- 正常 订单  -->
						<div v-if="state==0" class="fr wuliu wuliu_state">
							<p v-for="(v,i) in postList" :key="i" class="wl"><span>{{v.time}}</span><span> {{v.status}} {{v.context}}</span></p>
							<p></p>
							<p class="one" v-if="states == 0">订单已经提交，但未付款</p>
							<p class="one" v-if="states == 1">订单已经付款，等待发货</p>
							<p class="one" v-if="states == 2">{{list.ordertime}} 已提交订单，等待系统确认</p>
							<p class="one" v-if="states == 3">商品已确认收货，未评价</p>
							<p class="one" v-if="states == 5">订单已经取消</p>
						</div>
						<!-- 退款退货 -->
						<div v-if="state==1" class="fr">
							<p v-if="states == 6" class="one fn26">商家处理中</p>
							<p v-if="states == 7" class="one fn26">退款完成</p>
							<p v-if="states == 7" class="one fn18">
								退款金额
								<span>{{list.refund_money}}</span>
							</p>
						</div>
					</div>
					<!-- 商品 -->
					<div v-for="(v,i) in goodsList" :key="i" class="goodsBox">
						<div class="goodsTitle">
							<div>商品</div>
							<div>数量</div>
							<div>商品状态</div>
							<div>价格</div>
							<div>操作</div>
						</div>
						<div class="goods">
							<div>
								<img :src="v.goods_img" alt />
								<p class="two fr">{{v.goods_name}}</p>
							</div>
							<div>{{v.num}}</div>
							<div>
								<span>{{v.status_txt}}</span>
								<!-- <span v-if="states == 0">待支付</span>
								<span v-if="states == 1">待发货</span>
								<span v-if="states == 2">已提交</span>
								<span v-if="states == 3">确认收货</span>
								<span v-if="states == 5">已取消</span>
								<span v-if="v.status==6">退款申请中</span>
								<span v-if="v.status==7">退款完成</span>
								<span v-if="v.status==8">拒绝退款</span> -->
							</div>
							<div>¥ {{v.price}}</div>
			
							<div>
								<span v-if="v.is_refund==1" @click="$router.push({name:'我的订单退换',query:{infor:v,id:list.id}})">申请退款</span>
								<span v-if="v.comment_status==0&&states==3&&v.status!=6&&v.status!=7&&v.status!=8" @click="GoAssess(v)" >前往评价</span>
							</div>
						</div>
					</div>
					<!-- 价格 -->
					<div class="money">
						<span>运费:¥{{list.postage}}</span>
						<span>会员折扣:¥{{list.member_dis_money}}</span>
						<span>积分抵扣:¥{{list.money_score}}</span>
						<span>优惠：¥ {{list.money_youhui}}</span>
						付款：
						<span>￥{{list.money}}</span>
					</div>

					<el-dialog title :visible.sync="tab1" width="30%">
						<span>是否取消订单？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="tab1 = false">取 消</el-button>
							<el-button type="primary" @click="cancelOrder()">确 定</el-button>
						</span>
					</el-dialog>

					<el-dialog title :visible.sync="tab2" width="30%">
						<span>是否确认收货？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="tab2 = false">取 消</el-button>
							<el-button type="primary" @click="confirmGoods()">确 定</el-button>
						</span>
					</el-dialog>

					<el-dialog title :visible.sync="tab3" width="30%">
						<span>是否删除订单？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="tab3 = false">取 消</el-button>
							<el-button type="primary" @click="removeOrder()">确 定</el-button>
						</span>
					</el-dialog>
					
					<div class="bottom">
						<p @click="tab1=true" v-if="states==0">取消订单</p>
						<p @click="GoPay()" v-if="states==0">立即付款</p>
						<p @click="tab2=true" v-if="states==2 ">确认收货</p>
						<p @click="tab3=true" v-if="states== 4 || states== 5 || states== 7 ">删除订单</p>
					</div>
					
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "../header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部

	import {
		usergetinfo
	} from "@/request/user";
	import {
		getOrderDetails
	} from "@/request/index"; // 订单详情
	import {
		see_post
	} from "@/request/index"; //获取 物流 详情
	import {
		confirmGetGoods
	} from "@/request/index"; //确认收货
	import {
		delete_order
	} from "@/request/index"; // 删除订单
	import {
		cancel_order
	} from "@/request/index"; //取消订单

	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				states: 0, // 订单状态  0-待支付
				state: 0, // 判断是不是 正常 订单 有没有 退款
				state1: 1, // 正常订单
				state2: 1, //退款订单
				ids: "", //订单ID
				list: [], // 订单信息
				goodsList: [], // 商品 信息
				postList: [],
				tab1: false, //取消订单
				tab2: false, //确认收货订单
				tab3: false, //删除订单
				userList: [] // 个人信息
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.ids = this.$route.query.id;
			this.init();
			this.start();
		},
		methods: {
			// 去退款
			GoReturn() {
				this.$router.push({
					path: "/user/returnGoods?id=" + this.ids
				});
			},
			// 去支付
			GoPay() {
				this.$router.replace({
					path: `/pay?money=${this.list.money}&out_trade_no=${this.list.out_trade_no}&pay_type=alipayScan`
				});
			},
			//  取消订单 未支付
			cancelOrder() {
				let Athis = this;
				cancel_order({
					id: this.ids,
					token: this.$tokens
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "取消成功",
							type: "success"
						});
						setTimeout(function() {
							Athis.$emit("removeOrder");
						}, 600);
						this.tab1 = false;
						this.$router.push("/user/allOrder?state=-1");
					}
				});
			},
			// 删除订单
			removeOrder() {
				let Athis = this;
				delete_order({
					id: this.ids,
					token: this.$tokens
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "删除成功",
							type: "success"
						});
						this.tab3 = false;
						setTimeout(function() {
							Athis.$emit("removeOrder");
						}, 600);
						this.$router.push("/user/allOrder?state=-1");
					}
				});
			},
			// 前往评价
			GoAssess(v) {
				let msg = `/user/OrderAssess?did=${v.did}+&img=${v.goods_img}&name=${v.goods_name}`;
				this.$router.push({
					path: msg
				});
			},
			// 确认收货
			confirmGoods() {
				confirmGetGoods({
					id: this.ids,
					token: this.$tokens
				}).then(res => {
					if (res.code == 1) {
						this.start();
						this.tab2 = false;
					}
				});
			},
			// 初始化
			start() {
				getOrderDetails({
					token: this.$tokens,
					id: this.ids
				}).then(res => {
					this.list = res.data.datas;
					console.log(this.list);
					this.goodsList = res.data.datas.orderDetailsList;
					this.states = this.list.status;
					if (this.states > 5) {
						this.state = 1;
						if (this.states == 6) {
							this.state2 = 2;
						} else if (this.states == 7) {
							this.state2 = 3;
						}
					} else {
						if (this.states == 2 || this.states == 3 || this.states == 4) {
							if (this.list.post != "店铺自取") {
								see_post({
									token: this.$tokens,
									id: this.ids
								}).then(res => {
									if (res.code == 1) {
										this.postList = res.data.data;
									}
								});
							}
						}
						if (this.states != 5) {
							this.state1 = this.states + 1;
						}
					}
				});
			},
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	// 订单详情
	.Order_Details_content {
		border: 1px solid #e6e6e6;

		.state {
			padding-top: 40px;
			padding-bottom: 50px;
		}

		.txt {
			overflow: hidden;
			border: 1px solid #e6e6e6;
			border-left: 0px;
			border-right: 0px;
			display: flex;

			.fl {
				width: 350px;
			}

			.fr {
				width: 850px;
				text-align: left;
				padding-left: 52px;
				padding-top: 32px;
				border-left: 1px solid #e6e6e6;
				font-size: 20px;

				.fn26 {
					font-size: 26px;
				}

				.fn18 {
					font-size: 18px;
					color: #808080;
				}
				span:nth-child(1){
					flex: 3;
				}
				span:nth-child(2){
					flex: 10;
				}
				span {
					color: #4eeec8;
				}
			}

			.wuliu_state {
				font-size: 16px;
				.wl{
					display: flex;
					span{
						display: inline-block;
						color:#000000;
					}
					
				}
			}
		}

		.order_txt {
			width: 350px;
			// height: px;

			div {
				width: 350px;
				height: 63px;
				line-height: 63px;
				text-indent: 30px;
				background: rgba(78, 238, 200, 1);
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: rgba(255, 255, 255, 1);
			}

			p {
				margin-left: 30px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(128, 128, 128, 1);
				line-height: 40px;
			}
		}

		.goodsBox {
			margin-top: 26px;
			border: 1px solid #e6e6e6;
			border-left: 0px;
			border-right: 0px;
		}

		.goodsTitle {
			height: 64px;
			background: rgba(247, 247, 247, 1);
			display: flex;
			padding-left: 16px;
			padding-right: 16px;
			justify-content: space-around;

			>div {
				text-align: center;
				height: 64px;
				line-height: 64px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(51, 51, 51, 1);
				flex: 1.2;
			}

			>div:nth-of-type(1) {
				flex: 4;
			}
		}

		.wuliu {
			p {
				margin-bottom: 20px;
			}
		}

		.goods {
			display: flex;
			justify-content: space-around;

			>div {
				padding: 10px;
				text-align: center;
				height: 114px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(128, 128, 128, 1);
				overflow: hidden;
				text-align: center;
				flex: 1.1;
			}

			p {
				display: inline-block;
				width: 200px;
			}

			img {
				width: 140px;
				height: 114px;
			}

			span {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(78, 238, 200, 1);
				display: block;
				line-height: 30px;

			}

			>div:nth-of-type(1) {
				text-align: left;
				flex: 4;
			}
		}

		.money {
			text-align: right;
			height: 68px;
			line-height: 68px;
			background: rgba(247, 247, 247, 1);
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(26, 26, 26, 1);
			padding-right: 42px;
			span{
				display: inline-block;
				margin-right:10px;
				color: rgba(128, 128, 128, 1);
			}
		

			span:last-child{
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ff0000;
			}
		}

		.bottom {
			height: 104px;
			text-align: right;
			padding-top: 26px;
			padding-right: 42px;
			cursor: pointer;

			p {
				display: inline-block;
				height: 42px;
				line-height: 42px;
				padding-left: 16px;
				padding-right: 16px;
				border: 2px solid rgba(78, 238, 200, 1);
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(78, 238, 200, 1);
				margin-left: 16px;
			}
		}
	}
</style>
